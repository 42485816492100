import { URL_REGEX } from '../../constants/regex.constant';

export function capitalize(str) {
  return str.replace(/(^|\s)([a-z])/g, function(match, p1, p2) {
    return p1 + p2.toUpperCase();
  });
}

export function formatExternalUrl(url) {
  if (!url) return '';
  const cleanUrl = removeUrlProtocol(url);
  return `https://${cleanUrl}`;
}

export function formatDisplayedUrl(url) {
  if (!url) return '';
  return url.replace(/^(http:\/\/)|(https:\/\/)/, '');
}

export function formatMetaText(metaTag, type, term, page = 0, year = 0) {
  let pageTitle = '';
  page = parseInt(page, 10);
  year = parseInt(year, 10);

  const text = {
    title: {
      noYear: 'Find & Organize Hackathons',
      year: 'Find Hackathon',
    },
    desc: {
      noYear: 'List of upcoming hackathons',
      year: 'List of hackathons',
    }
  };

  if (year !== 0) pageTitle += text[metaTag].year;
  else pageTitle += text[metaTag].noYear;

  switch (type) {
    case 'theme':
      pageTitle += ' about';
      break;
    case 'organizer':
      pageTitle += ' organized by';
      break;
    case 'online':
      pageTitle += ' happening';
      break;
    default:
      pageTitle += ' in';
      break;
  };

  pageTitle += ` ${term}`;

  if (year) pageTitle += ` in ${year}`;
  if (page > 1) pageTitle += `, page ${page}`;

  return pageTitle;
}

export function formatSearchPath(search, page = 1, year) {
  if (search.type === 'online') {
    return `/${search.type}${ year ? `/${year}`: ''}${page && page > 1 ? `/${page}` : ''}`;
  }

  let searchTerm = search.searchTerm;
  if (search.type === 'search') {
    return `/${search.type}${ year ? `/${year}`: ''}${page && page > 1 ? `/${page}` : ''}?s=${searchTerm}`;
  }
  else if (search.type === 'city') {
    if (search.searchTerm?.city) searchTerm = `${slugify(search.searchTerm.country)}/${slugify(search.searchTerm.city)}`;
    else searchTerm = `${slugify(search.country)}/${slugify(search.city)}`
  }
  else if (search.type === 'country' || search.type === 'theme') searchTerm = slugify(search.searchTerm);
  else {
    if (Array.isArray(search.searchTerm)) searchTerm = search.searchTerm[0].toLowerCase();
    else searchTerm = search.searchTerm.toLowerCase();
  }

  return `/${search.type}/${searchTerm}${ year ? `/${year}`: ''}${page && page > 1 ? `/${page}` : ''}`;
}

export function getImageUrl(image, options = {}) {
  let imgUrl = '';
  if (URL_REGEX.test(image)) imgUrl = image;
  else {
    const formatedOptions = _cloudinaryURLFragment(options);
    imgUrl = `https://res.cloudinary.com/ideation/image/upload/${formatedOptions}${image}`
  }

  return imgUrl;
}

export function removeUrlProtocol(url) {
  if (!url) return '';
  return url.replace(/^[^\/]*(\/\/\/|:\/\/|\/\/)/, '');
}

export function slugify(string, encode = false, spaceFiler = '-') {
  // console.log(string);
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·_—';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz---';
  const p = new RegExp(a.split('').join('|'), 'g');

  let safeSlug = string.toString().toLowerCase()
    .trim()
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, 'and') // Replace & with 'and'
    .replace(/[^[a-zA-Z0-9أ-ي]-]+/g, '') // Arabic support
    .replace(/["'§@(){}\[\]#$*€^`£%°¨?!.:;,+=*$£€¥¨^°¶§“‘«¡®‚†™ºªπ∏‡ΩÒ∑∆ﬁﬂ¬|µ‰≤≥<>‹›≈⁄©¢◊√~ı∞¿…•÷≠±]/gm, '') // Remove special characters
    .replace(/\//g, '-')
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
  
  if (!encode) safeSlug = safeSlug.replace(/\s+/g, spaceFiler) // Replace spaces with '-' or specified character
  else safeSlug = safeSlug.replace(/\s+/g, encodeURI(' ')) // Replace spaces with -

  return safeSlug;
}

export function slugOfHackathon(event) {
  return `${slugify(event.name)}-${event.eb_id || event._id}`;
}

export function slugToId(eventSlug) {
  return (eventSlug || '').split('-').pop();
}

export function unslugCapitalize(str) {
  return capitalize(str.replace(/-/g, ' '));
}

// Private utilities
function _cloudinaryURLFragment(options) {
  const composition = [];

  // List of transformations accepted, if you need more, add from here:
  // http://cloudinary.com/documentation/image_transformations#transformations_reference

  if (options.width) {
    composition.push(`w_${options.width}`);
  }
  if (options.height) {
    composition.push(`h_${options.height}`);
  }
  if (options.crop) {
    composition.push(`c_${options.crop}`);
  }
  if (options.gravity) {
    composition.push(`g_${options.gravity}`);
  }
  if (options.radius) {
    composition.push(`r_${options.radius}`);
  }
  // Options for optimisation
  if (options.quality) {
    composition.push(`q_${options.quality}`);
  } else {
    composition.push('q_100'); // defaut quality to 100
  }
  if (options.format) {
    composition.push(`f_${options.format}`);
  } else {
    composition.push('f_auto'); // automatic image format
  }
  if (options.dpr) {
    composition.push(`dpr_${options.dpr}`);
  } else {
    composition.push('dpr_auto'); // automatic resolution
  }

  if (composition.length) {
    return composition.join(',') + '/';
  }

  return '';
}
